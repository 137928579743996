exports.components = {
  "component---src-pages-atriodp-js": () => import("./../../../src/pages/atriodp.js" /* webpackChunkName: "component---src-pages-atriodp-js" */),
  "component---src-pages-atriosummitcortesia-js": () => import("./../../../src/pages/atriosummitcortesia.js" /* webpackChunkName: "component---src-pages-atriosummitcortesia-js" */),
  "component---src-pages-corridadeaventura-js": () => import("./../../../src/pages/corridadeaventura.js" /* webpackChunkName: "component---src-pages-corridadeaventura-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leads-js": () => import("./../../../src/pages/leads.js" /* webpackChunkName: "component---src-pages-leads-js" */),
  "component---src-pages-leadspremedio-js": () => import("./../../../src/pages/leadspremedio.js" /* webpackChunkName: "component---src-pages-leadspremedio-js" */),
  "component---src-pages-matriculas-js": () => import("./../../../src/pages/matriculas.js" /* webpackChunkName: "component---src-pages-matriculas-js" */),
  "component---src-pages-matriculasnba-js": () => import("./../../../src/pages/matriculasnba.js" /* webpackChunkName: "component---src-pages-matriculasnba-js" */),
  "component---src-pages-palestrapaiseeducadores-2023-js": () => import("./../../../src/pages/palestrapaiseeducadores2023.js" /* webpackChunkName: "component---src-pages-palestrapaiseeducadores-2023-js" */),
  "component---src-pages-palestras-js": () => import("./../../../src/pages/palestras.js" /* webpackChunkName: "component---src-pages-palestras-js" */),
  "component---src-pages-palestrassummit-js": () => import("./../../../src/pages/palestrassummit.js" /* webpackChunkName: "component---src-pages-palestrassummit-js" */),
  "component---src-pages-sucesso-js": () => import("./../../../src/pages/sucesso.js" /* webpackChunkName: "component---src-pages-sucesso-js" */)
}

